import React from 'react';
import Link from 'gatsby-link';
import { isEmpty } from 'lodash';
import './_style.scss';
import Img from "gatsby-image";

const Hero = ( props ) => {


	const pageHero = props.data;
	const compClass = props.className;
	const heroId = 'hero-' + Math.floor(Math.random() * 9999);

	return ! isEmpty( pageHero && pageHero[0].compHero ) ? (

		<div id={ heroId } className={ "rw-full-hero carousel slide" + (typeof compClass !== "undefined" ? ' ' + compClass : '')  } data-bs-ride="carousel">


			<ol className="carousel-indicators">
				{ pageHero.map(
					( pagePost, pageIndex ) => (

						<React.Fragment key={pageIndex}>

							{ pagePost.compHero.map(
								( post, index ) => (
									<li key={heroId + '-' + index} data-bs-target={'#' + heroId } data-bs-slide-to={index} className={(index === 0 ? 'active' : '')}></li>
								)
							) }

						</React.Fragment>

					)
				) }
			</ol>

			<div className="carousel-inner">
				{ pageHero.map(
					( pagePost, pageIndex ) => (

						<React.Fragment key={pageIndex}>

						{ pagePost.compHero.map(
							( post, index ) => (

								<div key={ index } className={'carousel-item ' + (index === 0 ? 'active' : '')}>

									{ ! isEmpty( post.image ) ? (
										<Img
										className="d-block w-100"
										fluid={ post.image.sourceUrlSharp.childImageSharp.fluid }
										alt={ post.image.altText ? post.image.altText : 'Hero Image' }
										/>
									) : ('') }

									<div className="carousel-caption col offset-lg-6 col-lg-5">
										{ post.title ? (
											<h2 className="h1">{ post.title }</h2>
										) : ('') }

										{ post.description ? (
											<h3 className="">
												{ post.description }
											</h3>
										) : ('') }

										{ isEmpty( post.pageLinkText ) ? ('') : (
											<Link to={ ! isEmpty ( post.pageLink ) ? post.pageLink.uri : '#'  }>
												<button className="btn btn-primary text-base fw-400">{ post.pageLinkText }</button>
											</Link>
										) }
									</div>

								</div>

							)
						) }

						</React.Fragment>

					)
				) }
			</div>

			<a className="carousel-control-prev" href={'#' + heroId } role="button" data-bs-slide="prev">
			  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
			  <span className="visually-hidden">Previous</span>
			</a>
			<a className="carousel-control-next" href={'#' + heroId } role="button" data-bs-slide="next">
			  <span className="carousel-control-next-icon" aria-hidden="true"></span>
			  <span className="visually-hidden">Next</span>
			</a>

		</div>

	) : ('');


};

export default Hero;
