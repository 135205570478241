import React from 'react';
import { isEmpty } from 'lodash';
import { sanitize } from "../../../utils/functions";
import './_style.scss';
import Img from "gatsby-image";

const DestraSinistra = ( props ) => {


	const mainDestraSinistra = props.data;
	const compClass = props.className;
	const dxsxId = 'dxsx-' + Math.floor(Math.random() * 9999);


	return ! isEmpty( mainDestraSinistra && mainDestraSinistra[0].blocchi ) ? (

		<section id={ dxsxId } className={ "comp-destra-sinistra" + (typeof compClass !== "undefined" ? ' ' + compClass : '')  }>

			{ ! isEmpty( mainDestraSinistra ) ? (
				mainDestraSinistra.map(
					( pagePost, pageIndex ) => (

						<React.Fragment key={pageIndex}>
						{ pagePost.blocchi.map(
							( post, index ) => {

								const clImmagine = post.allineamento === 'sx' ? 'order-2' : 'order-1';
								const clTesto = post.allineamento === 'sx' ? 'order-2 order-md-1' : 'order-1 order-md-2';

								return (
									<div key={ index } className={'row blocco ' + (post.allineamento === 'sx' ? 'sinistra' : 'destra')}>

										<div className={ 'immagine col-md-6 ' + clImmagine }>
											{ ! isEmpty( post.foto ) ? (
													<Img
													className="d-block w-100"
													fluid={ post.foto[0].sourceUrlSharp.childImageSharp.fluid }
													alt={ post.foto[0].altText ? post.foto[0].altText : 'Immagine' }
													/>
											) : ('') }
										</div>

										<div className={'testo col-md-6 ' + clTesto}>
											{ post.titolo ? (
												<h2 className="titolo h3">{ post.titolo }</h2>
											) : ('') }

											{ post.descrizione ? ( // valuta uso di react-html-parser -> wysiwyg fa già escape
												<div dangerouslySetInnerHTML={{__html: sanitize(post.descrizione) }}>
												</div>
											) : ('') }
										</div>

									</div>
								)
							}
						) }
						</React.Fragment>

					)
				)
			) : null }

		</section>

	) : ('');


};

export default DestraSinistra;
